import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Routes, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { QuestionarioGuard } from './guardas/questionario.guard';
import { QuestionarioProvider } from './providers/questionario.provider';
import { PaginaNaoEncontradaComponent } from './pages/pagina-nao-encontrada/pagina-nao-encontrada.component';
import { QuestionarioRealizacaoGuard } from './guardas/questionario-realizacao.guard';
import { QuestionarioHomeGuard } from './guardas/questionario-home.guard';
import { QuestionarioConcluidoComponent } from './pages/questionario-concluido/questionario-concluido.component';

const routes: Routes = [
  { path: '', data: { name: 'home', roles: ['all'] }, redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home', data: { name: 'home', roles: ['home'] },
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'home/:codigoQuestionario', data: { name: 'home', roles: ['cdla'] },
    loadChildren: () => import('./pages/cdla/cdla.module').then(m => m.CdlaModule),
    canActivate: [QuestionarioHomeGuard]
  },
  {
    path: 'home/agendamento/:codigoQuestionario', data: { name: 'home', roles: ['cdla'] },
    loadChildren: () => import('./pages/cdla/cdla.module').then(m => m.CdlaModule),
    canActivate: [QuestionarioGuard]
  },
  {
    path: 'home/realizacao/:codigoQuestionario', data: { name: 'home', roles: ['cdla'] },
    loadChildren: () => import('./pages/cdla/cdla.module').then(m => m.CdlaModule),
    canActivate: [QuestionarioRealizacaoGuard]
  },
  {
    path: 'home/agendamento/:codigoQuestionario', data: { name: 'home', roles: ['cemupe'] },
    loadChildren: () => import('./pages/cemupe/cemupe.module').then(m => m.CemupeModule),
    canActivate: [QuestionarioGuard]
  },
  {
    path: 'home/realizacao/:codigoQuestionario', data: { name: 'home', roles: [ 'cemupe'] },
    loadChildren: () => import('./pages/cemupe/cemupe.module').then(m => m.CemupeModule),
    canActivate: [QuestionarioRealizacaoGuard]
  },
  {
    path: 'home/:codigoQuestionario', data: { name: 'home', roles: ['cemupe'] },
    loadChildren: () => import('./pages/cemupe/cemupe.module').then(m => m.CemupeModule),
    canActivate: [QuestionarioHomeGuard]
  }, {
    path: 'home/agendamento/:codigoQuestionario', data: { name: 'home', roles: ['show'] },
    loadChildren: () => import('./pages/show/show.module').then(m => m.ShowModule),
    canActivate: [QuestionarioGuard]
  },
  {
    path: 'home/realizacao/:codigoQuestionario', data: { name: 'home', roles: [ 'show'] },
    loadChildren: () => import('./pages/show/show.module').then(m => m.ShowModule),
    canActivate: [QuestionarioRealizacaoGuard]
  },
  {
    path: 'home/:codigoQuestionario', data: { name: 'home', roles: ['show'] },
    loadChildren: () => import('./pages/show/show.module').then(m => m.ShowModule),
    canActivate: [QuestionarioHomeGuard]
  }, 
  {
    path: 'home/agendamento/:codigoQuestionario', data: { name: 'home', roles: ['nova'] },
    loadChildren: () => import('./pages/nova/nova.module').then(m => m.NovaModule),
    canActivate: [QuestionarioGuard]
  },
  {
    path: 'home/realizacao/:codigoQuestionario', data: { name: 'home', roles: [ 'nova'] },
    loadChildren: () => import('./pages/nova/nova.module').then(m => m.NovaModule),
    canActivate: [QuestionarioRealizacaoGuard]
  },
  {
    path: 'home/:codigoQuestionario', data: { name: 'home', roles: ['nova'] },
    loadChildren: () => import('./pages/nova/nova.module').then(m => m.NovaModule),
    canActivate: [QuestionarioHomeGuard]
  }, 
  {
    path: 'home/agendamento/:codigoQuestionario', data: { name: 'home', roles: ['cedav'] },
    loadChildren: () => import('./pages/cedav/cedav.module').then(m => m.CedavModule),
    canActivate: [QuestionarioGuard]
  },
  {
    path: 'home/realizacao/:codigoQuestionario', data: { name: 'home', roles: [ 'cedav'] },
    loadChildren: () => import('./pages/cedav/cedav.module').then(m => m.CedavModule),
    canActivate: [QuestionarioRealizacaoGuard]
  },
  {
    path: 'home/:codigoQuestionario', data: { name: 'home', roles: ['cedav'] },
    loadChildren: () => import('./pages/cedav/cedav.module').then(m => m.CedavModule),
    canActivate: [QuestionarioHomeGuard]
  },
  // Not lazy-loaded routes
  { path: 'questionario-concluido', data: { name: 'questionario-concluido', roles: ['cdla', 'cemupe','nova','cedav', 'show'] }, component: QuestionarioConcluidoComponent },
  { path: 'pagina-nao-encontrada', data: { name: 'pagina-nao-encontrada', roles: ['cdla', 'cemupe','nova','cedav', 'show'] }, component: PaginaNaoEncontradaComponent },
  { path: '**', data: { name: 'pagina-nao-encontrada', roles: ['cdla', 'cemupe','nova','cedav', 'show'] }, redirectTo: '/pagina-nao-encontrada' }
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    ToastrModule.forRoot(),
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initApp,
    deps: [Injector],
    multi: true
  },
    QuestionarioGuard,
    QuestionarioProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function initApp(injector: Injector) {
  return () => {
    return new Promise((resolve) => {
      const orgId = environment.orgId;
      const filteredRoutes = routes.filter(r => {
        return r.data.roles.indexOf('all') !== -1 || r.data.roles.indexOf(orgId) !== -1;
      });
      const router: Router = injector.get(Router);
      router.resetConfig(filteredRoutes);
      resolve(1);
    });
  };
}